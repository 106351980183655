export const state = () => ({
  cartItemList: []
})

export const mutations = {
  UPDATE_CART (state, { item, qty, isAdd, rootState }) {
    const prod = state.cartItemList.find(elem => Number(elem.product_id) === Number(isAdd ? item.id : item.product.id))
    if (prod) {
      if (isAdd) {
        prod.qty += qty
      } else {
        prod.qty = qty
      }
      // prod.rate = prod.qty >= prod.product.wholesale_min ? prod.product.wholesale : prod.product.price
      prod.rate = getProductPrice(prod)
    } else {
      state.cartItemList.push({ product: item, product_id: item.id, rate: item.price, total: null, note: null, qty, attributes: [] })
    }
    localStorage.setItem('cartItemList', JSON.stringify(state.cartItemList))
  },
  UPD_PRODUCT_IN_CART: (state, { item }) => {
    const cartItem = state.cartItemList.find(e => Number(e.product_id) === Number(item.id))
    cartItem.product = item
    localStorage.setItem('cartItemList', JSON.stringify(state.cartItemList))
  },
  UDP_QTY (state, { item, type, rootState }) {
    const prod = state.cartItemList.find(elem => Number(elem.product_id) === Number(item.id))
    prod.qty = type ? prod.qty + 1 : prod.qty > 0 ? prod.qty - 1 : prod.qty
    // prod.rate = prod.qty >= prod.product.wholesale_min ? prod.product.wholesale : prod.product.price
    prod.rate = getProductPrice(prod)
    localStorage.setItem('cartItemList', JSON.stringify(state.cartItemList))
  },
  UDP_ATTR (state, { item, attributes, rootState }) {
    const prod = state.cartItemList.find(elem => Number(elem.product_id) === Number(item.id))
    prod.attributes = attributes
    localStorage.setItem('cartItemList', JSON.stringify(state.cartItemList))
  },
  CLEAR_CART (state, productList) {
    state.cartItemList = productList
    localStorage.setItem('cartItemList', JSON.stringify(state.cartItemList))
  },
  REMOVE_CART_ITEM (state, { item }) {
    const record = state.cartItemList.find(elem => Number(elem.product_id) === Number(item.id))
    state.cartItemList.splice(state.cartItemList.indexOf(record), 1)
    localStorage.setItem('cartItemList', JSON.stringify(state.cartItemList))
  }
}

export const actions = {
  clearCart: ({ commit }) => {
    commit('CLEAR_CART', [])
  },
  updateCart ({ commit, rootState }, { item, qty, isAdd }) {
    commit('UPDATE_CART', { item, qty, isAdd, rootState })
    // const mobCartBody = document.querySelector('.mobCartBody')
    // mobCartBody.scrollLeft = mobCartBody.scrollWidth
  },
  udpQty ({ commit, rootState }, { item, type }) {
    commit('UDP_QTY', { item, type, rootState })
  },
  udpAttr ({ commit, rootState }, { item, attributes }) {
    commit('UDP_ATTR', { item, attributes, rootState })
  },
  removeItemInCart ({ commit }, { item }) {
    commit('REMOVE_CART_ITEM', { item })
  }
}

export const getters = {
  cartItemList: (state) => {
    return state.cartItemList
  },
  cartValue: (state) => {
    let res = 0
    state.cartItemList.map((item) => {
      // res += (!isNaN(item.special) && item.special > 0 && item.special ? item.special : item.price) * item.qty
      res += getProductPrice(item) * item.qty
    })
    return res
  },
  taxValue: (state, getters, rootState) => {
    // return !rootState.auth.user ? (getters.cartValue * 0.16) : Number(rootState.auth.user.whs_tax) ? getters.cartValue : (getters.cartValue * 0.16)
    return 0
  },
  prodPrice: (state) => {
    return (item) => {
      const prod = state.cartItemList.find(elem => elem.product_id === item.id)
      // return (!isNaN(prod.special) && prod.special > 0 && prod.special) ? prod.special : prod.price
      // return prod.qty >= Number(prod.product.wholesale_min) ? prod.product.wholesale : prod.product.price
      return getProductPrice(prod)
    }
  },
  checkProd: (state) => {
    return (item) => {
      // const prod = state.cartItemList.find(element => element.id === item.id)
      return state.cartItemList.find(elem => Number(elem.product_id) === Number(item.id))
    }
  }
}

export const getProductPrice = (prod) => {
  // return prod.product.offer ? prod.product.offer_price : prod.qty >= Number(prod.product.wholesale_min) ? prod.product.wholesale : prod.product.price
  return prod.product.wholesale
}
